const KGS_IN_LBS = 0.453592
const LBS_IN_KG = 2.20462

export const kgToLbs = (kg) => kg / KGS_IN_LBS
export const lbsToKg = (lbs) => lbs / LBS_IN_KG

export default () => ({
  init() {
    this.store = Alpine.store("data")
  },

  isMetric() {
    return this.store.values.measurementSystem === "metric"
  },

  getFutureDayDate(daysFromNow) {
    const now = new Date(Date.now())
    const futureDate = new Date(now.getTime() + daysFromNow * 24 * 60 * 60 * 1000)

    return futureDate.toLocaleDateString(pipelineConfig.language, {
      day: "numeric",
      month: "long",
      year: "numeric",
    })
  },

  getGoalDate() {
    return this.getFutureDayDate(7 * 12)
  },

  getFirstWeekDate() {
    return this.getFutureDayDate(7)
  },

  normalizedHeight() {
    if (this.isMetric()) {
      return this.store.values.heightCm
    } else {
      return this.store.values.heightFt * 30.48 + this.store.values.heightIn * 2.54
    }
  },

  idealWeight() {
    return Math.round((this.normalizedHeight() * 2 * 21.1) / 10000)
  },

  normalizedWeight() {
    if (this.isMetric()) {
      return this.store.values.weightKg
    } else {
      return this.store.values.weightLbs * KGS_IN_LBS
    }
  },

  normalizedTargetWeight() {
    if (this.isMetric()) {
      return this.store.values.targetWeightKg
    } else {
      return this.store.values.targetWeightLbs * KGS_IN_LBS
    }
  },

  targetOverWeight() {
    return Math.ceil(this.normalizedWeight() - this.normalizedTargetWeight())
  },

  overWeight() {
    return this.normalizedWeight() - this.idealWeight()
  },

  firstMonth() {
    let val = (30 / 100) * this.overWeight()

    if (val > 7) {
      return 7
    } else if (val < 4) {
      return 4
    }

    return Math.floor(val)
  },

  getFirstWeekValue() {
    //Set normally calculated values
    const calculatedFirstWeek = this.isMetric()
      ? Math.round(this.firstMonth() / 4)
      : Math.round(kgToLbs(this.firstMonth() / 4))

    //Set desired weight loss
    const { weightKg, targetWeightKg, weightLbs, targetWeightLbs } = this.store.values
    const weight = this.isMetric()
      ? weightKg - targetWeightKg
      : lbsToKg(weightLbs - targetWeightLbs)

    //Exception if the desired weight loss is less than 4kg
    if (weight < 4) {
      if (weight > 1) {
        const calculatedFirstWeekException = this.isMetric()
          ? Math.round(weight / 2)
          : Math.round(kgToLbs(weight / 2))

        return calculatedFirstWeekException >= 0 ? `-${calculatedFirstWeekException}` : null
      } else {
        return 0 //Force 0 not to show 0.5
      }
    }

    return calculatedFirstWeek >= 0 ? `-${calculatedFirstWeek}` : null
  },

  getFirstWeekResultValue() {
    const diff = Number(this.getFirstWeekValue()) * -1

    if (this.isMetric()) {
      const diffNumber = diff || 4
      const isUnderWeightKg = this.store.values.weightKg - this.store.values.targetWeightKg < 0

      return isUnderWeightKg
        ? Math.floor(this.store.values.weightKg + diffNumber)
        : Math.floor(this.store.values.weightKg - diffNumber)
    } else {
      const diffNumber = diff || kgToLbs(4)
      const isUnderWeightLbs = this.store.values.weightLbs - this.store.values.targetWeightLbs < 0

      return isUnderWeightLbs
        ? Math.floor(this.store.values.weightLbs + diffNumber)
        : Math.floor(this.store.values.weightLbs - diffNumber)
    }
  },
})
